import { ComponentRef } from "@angular/core";
import { Base } from "@zixi/models";
import { KeyMap, SshKey } from "../../../models/shared";
import { FunctionRef, ZxSshKeysTableRowActionsComponent } from "./zx-ssh-keys-table-row-actions.component";

type booleanCallback<T = KeyMap<Base>> = (row: T) => boolean;

export const assignSsHKeysTableRowActionsInputsFactory =
    <T = SshKey>(
        cantDownload: booleanCallback<T>,
        downloadLink: FunctionRef<T, string>,
        canEdit: booleanCallback<T>,
        editRef: FunctionRef<T>,
        canDelete: booleanCallback<T>,
        deleteRef: FunctionRef<T>
    ) =>
    (ComponentRef: ComponentRef<ZxSshKeysTableRowActionsComponent>, row: KeyMap<T>): void => {
        const comp = ComponentRef.instance;
        comp.cantDownload = cantDownload(row);
        comp.downloadLink = downloadLink;
        comp.canEdit = canEdit(row);
        comp.editRef = editRef;
        comp.canDelete = canDelete(row);
        comp.deleteRef = deleteRef;
        comp.row = row;
    };
