import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import * as _ from "lodash";

import { Constants } from "../../../../constants/constants";
import { SshKeysService } from "../ssh-keys.service";
import { SshKey } from "../../../../models/shared";
import { TitleCasePipe } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../../services/title.service";
import { UntypedFormControl, Validators } from "@angular/forms";
@Component({
    selector: "app-ssh-key-form",
    templateUrl: "./ssh-key-form.component.html",
    providers: [TitleCasePipe]
})
export class SshKeyFormComponent implements OnInit, OnDestroy {
    sshKey: SshKey;
    sshKeyId: number;
    sshKeyNames: string[];
    action: string;

    importKey = true;
    submitted = false;
    minLength = 2;
    isEdit = false;

    loading = true;
    saving = false;
    constants = Constants;

    private sshKeysSubscription: Subscription;
    tagsControl = new UntypedFormControl([], [Validators.required]);
    nameControl = new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(Constants.validators.name)
    ]);
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private sks: SshKeysService,
        private translate: TranslateService,
        private titleService: TitleService,
        private titlecasePipe: TitleCasePipe
    ) {
        // The ActivatedRoute dies with the routed component and so the subscription dies with it.
        this.route.paramMap.subscribe(params => {
            this.sshKeyId = parseInt(params.get("id"), 10);
            this.action = params.get("action");
            if (this.sshKeyId) {
                this.sks.refreshSshKeys();

                this.sks.sshKeys.pipe(take(1)).subscribe(sshKeys => {
                    this.sshKey = Object.assign(
                        {},
                        sshKeys.find(t => t.id === this.sshKeyId)
                    );
                    this.prepForm();
                    this.loading = false;
                });
            } else {
                this.prepForm();
                this.loading = false;
            }
        });
    }

    prepForm() {
        if (this.sshKey) {
            this.tagsControl.setValue(this.sshKey.resourceTags);
            if (this.action === "edit") {
                this.nameControl.setValue(this.sshKey.name);
                this.isEdit = true;
            }
        }

        if (!this.sshKey && !this.isEdit) {
            this.sshKey = new SshKey();
            this.sshKey.name = "";
        }

        // Set Title
        this.titleService.setTitle(
            this.translate.instant("SSH_KEY") +
                " - " +
                (this.action ? this.titlecasePipe.transform(this.action) : "New") +
                " " +
                (this.sshKey.name ? this.sshKey.name : "")
        );
    }

    ngOnInit() {
        this.sks.refreshSshKeys();

        this.sshKeysSubscription = this.sks.sshKeys.subscribe((sshKeys: SshKey[]) => {
            this.sshKeyNames = sshKeys.map(t => t.name);
            if (this.isEdit) this.sshKeyNames = sshKeys.filter(t => t.id !== this.sshKeyId).map(t => t.name);
        });
    }

    ngOnDestroy() {
        this.sshKeysSubscription.unsubscribe();
    }

    async onSubmit() {
        this.saving = true;

        const model = {
            name: this.nameControl.value,
            resource_tag_ids: _.map(this.tagsControl.value, "id"),
            rsa_public: this.isEdit || !this.importKey ? undefined : this.sshKey.rsa_public
        };

        if (this.isEdit) {
            const result = await this.sks.updateSshKey(this.sshKey, model);
            if (result !== false) {
                this.saving = false;
                this.router.navigate([Constants.urls.configuration.sshKeys]);
            } else this.saving = false;
        } else {
            const result = await this.sks.addSshKey(model);
            if (result !== false) {
                this.saving = false;
                this.router.navigate([Constants.urls.configuration.sshKeys]);
            } else this.saving = false;
        }
    }

    cancel() {
        this.router.navigate([Constants.urls.configuration.sshKeys]);
    }
}
