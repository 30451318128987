import { Component, OnInit, OnDestroy, ViewChild, ComponentRef } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, firstValueFrom, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import cloneDeep from "lodash/cloneDeep";

import { Constants } from "../../../../constants/constants";
import { ModalService } from "../../../../components/shared/modals/modal.service";
import { UsersService } from "../../../account-management/users/users.service";
import { KeyMap, SshKey } from "../../../../models/shared";

import { SshKeysService } from "../ssh-keys.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../../services/title.service";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { NamesPipe } from "../../../../pipes/names.pipe";
import { ZxSshKeysTableRowActionsComponent } from "src/app/components/shared/zx-ssh-keys-table-row-actions/zx-ssh-keys-table-row-actions.component";
import { assignSsHKeysTableRowActionsInputsFactory } from "src/app/components/shared/zx-ssh-keys-table-row-actions/zx-ssh-keys-table-row-actions.table-adapter";
import { ZxTagsListComponent } from "src/app/components/shared/zx-tags-list/zx-tags-list.component";

@Component({
    selector: "app-ssh-key-list",
    templateUrl: "./ssh-key-list.component.html",
    providers: [NamesPipe]
})
export class SshKeyListComponent implements OnInit, OnDestroy {
    loading = true;
    refreshing = false;
    sshKeys: SshKey[];
    selectedRows: Array<SshKey> = [];

    isAdmin: boolean;
    urls = Constants.urls;

    private sshKeysSubscription: Subscription;

    private sshKeysBS$ = new BehaviorSubject<SshKey[]>([]);

    tableColumnsSchema: TableSchema[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            width: 280,
            visible: true,
            sticky: 1,

            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<SshKey>>(
                (row: KeyMap<SshKey>): string => row?.name,
                (row: KeyMap<SshKey>): string => row?.name,
                (): boolean => true
            ),
            sortBy: (row: KeyMap<SshKey>): string => row?.name,
            textValue: (row: KeyMap<SshKey>): string => row?.name
        },
        {
            header: this.translate.instant("TAGS"),
            columnDef: "accessTags",
            width: 280,
            visible: true,
            sticky: 2,

            component: ZxTagsListComponent,
            assignComponentsInputs: (
                zecComponentRef: ComponentRef<ZxTagsListComponent>,
                row: KeyMap<SshKey>,
                searchTerm: string[]
            ) => {
                const zecCompRef = zecComponentRef.instance;
                const props = {
                    model: row,
                    searchTerm: searchTerm
                };
                for (const key in props) {
                    const value = props[key];
                    zecCompRef[key] = value;
                }
            },
            textValue: (row: KeyMap<SshKey>) => row.resourceTags.map(t => t.name).join(""),
            sortBy: (row: KeyMap<SshKey>) => row.resourceTags.map(t => t.name).join("")
        },
        {
            header: this.translate.instant("ACTIONS"),
            columnDef: "actions",
            width: 105,
            sticky: 3,
            visible: true,
            align: "right",
            component: ZxSshKeysTableRowActionsComponent,
            assignComponentsInputs: assignSsHKeysTableRowActionsInputsFactory<SshKey>(
                (row: KeyMap<SshKey>): boolean => !row.hasRsaPrivate,
                (row: KeyMap<SshKey>) => `/api/access_keys/${row.id}/download`,
                (row: KeyMap<SshKey>): boolean => row?.hasRsaPrivate,
                (row: KeyMap<SshKey>): void => this.edit(row),
                (row: KeyMap<SshKey>): boolean => row?.hasRsaPrivate,
                (row: KeyMap<SshKey>): Promise<void> => this.delete(row)
            )
        }
    ];

    constructor(
        private router: Router,
        private ts: SshKeysService,
        private modalService: ModalService,
        private userService: UsersService,
        private mixpanelService: MixpanelService,
        private translate: TranslateService,
        private titleService: TitleService,
        private namesPipe: NamesPipe
    ) {
        // Set Title
        this.titleService.setTitle(this.translate.instant("NAVIGATION.SSH_KEYS"));
    }

    ngOnInit() {
        this.loading = true;

        // isAdmin
        this.userService.isAdmin.pipe(take(1)).subscribe(bool => {
            this.isAdmin = bool;
        });

        this.sshKeysSubscription = this.ts.sshKeys.subscribe(sshKeys => {
            this.sshKeys = sshKeys;
            if (this.sshKeys) {
                this.sshKeysBS$.next(cloneDeep(this.sshKeys));
                this.loading = false;
            }
        });
    }

    ngOnDestroy() {
        this.sshKeysSubscription.unsubscribe();
    }

    async refresh() {
        this.refreshing = true;

        const sshKeys = firstValueFrom(this.ts.refreshSshKeys());
        await Promise.all([sshKeys]);

        this.refreshing = false;
    }

    async multiAction(action: string, func: (sshKey: SshKey) => Promise<unknown>) {
        const result = await this.modalService.confirmMultiple(action, "SSH_KEY", this.selectedRows, func);
        if (result.actionTaken) {
            this.mixpanelService.sendEvent(this.translate.instant(action).toLowerCase() + " multiple ssh keys");
            if (action === "DELETE") this.selectedRows = [];
        }
    }

    multiDelete() {
        this.multiAction("DELETE", async (sshKey: SshKey) => this.ts.deleteSshKey(sshKey));
    }

    async multiEdit() {
        await this.modalService.editMultiple(
            "SSH_KEY",
            this.selectedRows,
            async (key: SshKey, model) => {
                return this.ts.updateSshKey(key.id, model);
            },
            { customContainer: ".ssh-keys" }
        );
    }

    edit(sshKey: SshKey): void {
        this.router.navigate([Constants.urls.configuration.sshKeys, sshKey.id, "edit"]);
    }

    async delete(sshKey: SshKey) {
        await this.modalService.confirm(
            "DELETE",
            "SSH_KEY",
            async () => {
                const id = sshKey.id;
                const result = await this.ts.deleteSshKey(sshKey);
                if (result) {
                    this.mixpanelService.sendEvent("delete ssh key", { id });
                } else return false;
            },
            sshKey.name
        );
    }

    get sshKeys$() {
        return this.sshKeysBS$.asObservable();
    }

    onSelectionRows(selectedRows: SshKey[]): void {
        this.selectedRows = selectedRows;
    }
}
