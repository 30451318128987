import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, Subscriber } from "rxjs";

import { SshKeysService } from "../ssh-keys.service";
import { SshKey } from "../../../../models/shared";

@Injectable({
    providedIn: "root"
})
export class SshKeyListResolverService implements Resolve<SshKey[]> {
    constructor(private tps: SshKeysService) {}

    resolve(): Observable<SshKey[]> | Observable<never> {
        return new Observable((observe: Subscriber<SshKey[]>) => {
            this.tps.refreshSshKeys().subscribe((sshKeys: SshKey[]) => {
                observe.next(sshKeys);
                observe.complete();
            });
        });
    }
}
