import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// Modules
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesome } from "../../../modules/font-awesome/font-awesome.module";
import { SharedModule } from "../../../modules/shared/shared.module";

// Forms
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";

// Components
import { SshKeysComponent } from "./ssh-keys.component";
import { SshKeyFormComponent } from "./ssh-key-form/ssh-key-form.component";
import { SshKeyListComponent } from "./ssh-key-list/ssh-key-list.component";

// Routes
import { SshKeysRouting } from "./ssh-keys.routing";

@NgModule({
    imports: [CommonModule, FormsModule, NgSelectModule, NgbModule, FontAwesome, SharedModule, SshKeysRouting],
    declarations: [SshKeysComponent, SshKeyFormComponent, SshKeyListComponent]
})
export class SshKeysModule {}
