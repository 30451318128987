import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, ReplaySubject } from "rxjs";
import { map, share } from "rxjs/operators";

import { TranslateService } from "@ngx-translate/core";
import { Constants } from "../../../constants/constants";
import { SshKey } from "../../../models/shared";

import { AuthService } from "src/app/services/auth.service";

@Injectable({
    providedIn: "root"
})
export class SshKeysService {
    sshKeys: Observable<SshKey[]>;
    private sshKeys$: ReplaySubject<SshKey[]>;
    private dataStore: {
        sshKeys: SshKey[];
    };

    constructor(private authService: AuthService, private http: HttpClient, private translate: TranslateService) {
        this.reset();

        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (!isLoggedIn) this.reset();
        });
    }

    private reset() {
        this.dataStore = {
            sshKeys: []
        };

        this.sshKeys$ = new ReplaySubject(1) as ReplaySubject<SshKey[]>;
        this.sshKeys = this.sshKeys$.asObservable();
    }

    private prepSshKey(sshKey: SshKey) {
        return sshKey;
    }

    private updateStore(sshKey: SshKey, merge: boolean): void {
        this.prepSshKey(sshKey);

        const currentIndex = this.dataStore.sshKeys.findIndex(g => g.id === sshKey.id);
        if (currentIndex === -1) {
            this.dataStore.sshKeys.push(sshKey);
            return;
        } else if (merge) {
            const current = this.dataStore.sshKeys[currentIndex];

            Object.assign(current, sshKey);

            const relationships = [];
            relationships.forEach(overwrite => {
                if (current[overwrite.id] == null) current[overwrite.obj] = null;
            });
        } else {
            this.dataStore.sshKeys[currentIndex] = sshKey;
        }
    }

    refreshSshKeys(): Observable<SshKey[]> {
        const sshKeys$ = this.http
            .get<{ result: SshKey[]; success: boolean }>(Constants.apiUrl + Constants.apiUrls.accessKeys)
            .pipe(share());

        sshKeys$.subscribe(
            data => {
                const sshKeys: SshKey[] = data.result;

                this.dataStore.sshKeys.forEach((existing, existingIndex) => {
                    const newIndex = sshKeys.findIndex(tp => tp.id === existing.id);
                    if (newIndex === -1) this.dataStore.sshKeys.splice(existingIndex, 1);
                });

                sshKeys.forEach(tp => this.updateStore(tp, true));

                this.sshKeys$.next(Object.assign({}, this.dataStore).sshKeys);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_TRANSCODING_PROFILES"), error)
        );

        return sshKeys$.pipe(map(r => r.result));
    }

    async addSshKey(model: Record<string, unknown>): Promise<SshKey | false> {
        try {
            const result = await this.http
                .post<{ success: boolean; result: SshKey }>(Constants.apiUrl + Constants.apiUrls.accessKeys, model)
                .toPromise();
            const sshKey: SshKey = result.result;

            this.updateStore(sshKey, false);

            this.sshKeys$.next(Object.assign({}, this.dataStore).sshKeys);
            return sshKey;
        } catch (error) {
            return false;
        }
    }

    async deleteSshKey(sshKey: SshKey | number): Promise<boolean> {
        try {
            const result = await this.http
                .delete<{ success: boolean; result: number }>(
                    Constants.apiUrl +
                        Constants.apiUrls.accessKeys +
                        "/" +
                        `${typeof sshKey === "number" ? sshKey : sshKey.id}`
                )
                .toPromise();

            const deletedId: number = result.result;

            const index = this.dataStore.sshKeys.findIndex(tp => tp.id === deletedId);
            if (index !== -1) this.dataStore.sshKeys.splice(index, 1);

            this.sshKeys$.next(Object.assign({}, this.dataStore).sshKeys);

            return true;
        } catch (error) {
            return false;
        }
    }

    async updateSshKey(sshKey: SshKey | number, model: Record<string, unknown>): Promise<SshKey | false> {
        try {
            const result = await this.http
                .put<{ success: boolean; result: SshKey }>(
                    Constants.apiUrl +
                        Constants.apiUrls.accessKeys +
                        "/" +
                        `${typeof sshKey === "number" ? sshKey : sshKey.id}`,
                    model
                )
                .toPromise();

            const updatedSshKey: SshKey = result.result;
            this.updateStore(updatedSshKey, true);

            this.sshKeys$.next(Object.assign({}, this.dataStore).sshKeys);

            return updatedSshKey;
        } catch (error) {
            return false;
        }
    }
}
