<div class="panel-container">
    <!-- Table -->
    <div id="list-panel" class="list-panel pb-0" #listPanel>
        <div class="list-container overflow-y-hidden">
            <app-table-list
                [tableName]="'ssh_key'"
                [displayTableName]="'SSH_KEYS' | translate"
                [data]="sshKeys$ | async"
                [(tableSchema)]="tableColumnsSchema"
                [(selectedRows)]="selectedRows"
                [showReport]="false"
                [isColumnSelectionNeeded]="false"
                [refreshing]="loading"
                [selectable]="false"
                [hoverable]="false"
            >
                <div *ngIf="selectedRows.length > 0">
                    <app-zx-action-buttons
                        [toggleMuteButton]="false"
                        [enableButton]="false"
                        [toggleStateButton]="false"
                        (multiEdit)="multiEdit($event)"
                        (multiDelete)="multiDelete($event)"
                    >
                    </app-zx-action-buttons>
                </div>

                <!-- Actions -->
                <div class="form-group mb-0" *ngIf="selectedRows.length === 0">
                    <button type="button" class="btn btn-outline-primary me-2" routerLink="/{{ urls.configuration.sshKeys }}/new">
                        <fa-icon icon="plus" size="sm"></fa-icon>
                        <span class="d-none d-sm-inline-block ms-1">{{ "ADD" | translate }}</span>
                    </button>
                    <button type="button" class="btn btn-outline-secondary" (click)="refresh()" title="{{ 'REFRESH' | translate }}">
                        <fa-icon icon="sync-alt" size="sm" [spin]="refreshing"></fa-icon>
                        <span class="d-none d-sm-inline-block ms-1">{{ "REFRESH" | translate }}</span>
                    </button>
                </div>
            </app-table-list>
        </div>
    </div>
</div>
