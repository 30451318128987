import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

import { UsersService } from "../../account-management/users/users.service";
import { User } from "src/app/models/shared";
import { IoServersService, IoServer } from "../../../components/io-servers/io-servers.service";
import { ClipboardService } from "ngx-clipboard";

@Component({
    selector: "app-ssh-keys",
    templateUrl: "./ssh-keys.component.html",
    host: { class: "ssh-keys" }
})
export class SshKeysComponent implements OnInit, OnDestroy {
    userAccount: User;
    ioServers: IoServer[];

    private userAccountSubscription: Subscription;
    private ioServersSubscription: Subscription;

    constructor(private us: UsersService, private ioServersService: IoServersService, private cbs: ClipboardService) {}

    ngOnInit() {
        this.userAccountSubscription = this.us.user.subscribe(user => {
            this.userAccount = user;
        });

        this.ioServersSubscription = this.ioServersService.ioServers.subscribe(ioServers => {
            this.ioServers = ioServers;
        });

        this.ioServersService.refreshIoServers();
    }

    ngOnDestroy() {
        if (this.userAccountSubscription) this.userAccountSubscription.unsubscribe();
        if (this.ioServersSubscription) this.ioServersSubscription.unsubscribe();
    }

    copyString(pw: string) {
        this.cbs.copy(pw);
    }
}
